<template>
  <div class="preview">
    <div class="detail-container">
      <div class="back-icon" v-if="back == 0">
        <i class="el-icon-arrow-left" @click="goBack"></i>
      </div>
      <div class="file-content" @scroll="scrollEvent">
        <!-- 报告内容...... -->
        <pdf
          v-for="item in pageArray"
          :key="item"
          :src="src"
          :page="item"
        ></pdf>
      </div>
    </div>
  </div>
</template>

<script>
import CMapReaderFactory from "vue-pdf/src/CMapReaderFactory.js";
import pdf from "vue-pdf";
import uni from "../../utils/uni-webview";
export default {
  components: {
    pdf
  },
  data() {
    return {
      src: "", // 研报地址
      numPages: "", // 总页数
      times: 1,
      yushu: 1,
      pageArray: [],
      currentPage: 1,
      nextShow: false,
      reportId: "", // 研报id
      back: 0
    };
  },
  mounted() {
    this.reportId = reportId;
    this.$session.setLoginToken(this.$route.query.token);
    let reportId = this.$route.query.id;
    this.getReportPdf(reportId);
    if (this.$route.query.back == 1) {
      this.back = 1;
    } else {
      this.back = 0;
    }
  },
  methods: {
    goBack() {
      uni.navigateBack();
    },
    scrollEvent(e) {
      if (
        Math.ceil(e.currentTarget.scrollTop + e.currentTarget.clientHeight) >=
        e.currentTarget.scrollHeight
      ) {
        //容差：20px
        console.log("滚动到底部");
        if (this.currentPage == this.times - 1) {
          this.nextShow = false;
        }
        if (this.currentPage == this.times) {
          // this.nextShow = false;
          return;
        }
        if ((this.currentPage + 1) * 5 <= this.numPages) {
          for (
            let i = this.currentPage * 5 + 1;
            i <= (this.currentPage + 1) * 5;
            i++
          ) {
            this.pageArray.push(i);
          }
        } else {
          for (let i = this.currentPage * 5 + 1; i <= this.numPages; i++) {
            this.pageArray.push(i);
          }
        }
        this.currentPage++;
      }
    },
    // 获取报告文件数据
    getReportPdf(reportId) {
      // 预览
      const loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.3)",
        fullscreen: false
      });
      this.$api.research
        .getReportPdf({
          reportId,
          type: 1
        })
        .then(res => {
          console.log(res, "111");
          this.src = this.$utils.getObjectURL(res);
          // 获取pdf页数
          let src = pdf.createLoadingTask({
            url: this.src,
            CMapReaderFactory
          });
          src.promise.then(pdf => {
            console.log(pdf.numPages, "pdf.numPages");
            this.numPages = pdf.numPages;
            this.times = Number.parseInt(this.numPages / 5);
            this.yushu = this.numPages % 5;
            if (this.yushu > 0) {
              this.times++;
              if (this.times == 1) {
                this.nextShow = false;
                for (let i = 1; i <= this.yushu; i++) {
                  this.pageArray.push(i);
                }
              } else {
                this.nextShow = true;
                for (let i = 1; i <= 5; i++) {
                  this.pageArray.push(i);
                }
              }
            } else if (this.yushu == 0) {
              if (this.times == 1) {
                this.nextShow = false;
              } else {
                this.nextShow = true;
              }
              for (let i = 1; i <= 5; i++) {
                this.pageArray.push(i);
              }
            }
          });
        })
        .catch(msg => {
          if (msg?.msg) {
            this.$message.error(msg?.msg);
          }
        })
        .finally(() => {
          loading.close();
        });
    },
    // 超出继续阅读
    next() {
      console.log(this.currentPage, this.times);
      if (this.currentPage == this.times - 1) {
        this.nextShow = false;
      }
      if (this.currentPage == this.times) {
        // this.nextShow = false;
        return;
      }
      if ((this.currentPage + 1) * 5 <= this.numPages) {
        for (
          let i = this.currentPage * 5 + 1;
          i <= (this.currentPage + 1) * 5;
          i++
        ) {
          this.pageArray.push(i);
        }
      } else {
        for (let i = this.currentPage * 5 + 1; i <= this.numPages; i++) {
          this.pageArray.push(i);
        }
      }
      this.currentPage++;
    }
  }
};
</script>
<style lang="less">
@import "./index.less";
</style>
